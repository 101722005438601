var render = function () {
  var _vm$preview, _vm$form$_vm$step, _vm$form$_vm$step2, _vm$form$_vm$step3, _vm$form$_vm$step4, _vm$form$_vm$step5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.meal-plan-schedule.detail'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('TabsMealPlan', {
    attrs: {
      "mode": "edit",
      "tabs": _vm.subTabs
    }
  }), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.saveState === null ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px",
      "flex-shrink": "0"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllMealPlanForm && _vm.saveState !== false || _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSaveDraft
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [!_vm.isProfilGiziValid ? _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "background-color": "danger.50",
      "padding": "6px 10px",
      "border-radius": "8px",
      "align-items": "center",
      "gap": "10px",
      "margin-bottom": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-warn.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#D32737"
    }
  }), _c('c-text', {
    attrs: {
      "color": "danger.400",
      "font-size": "14px"
    }
  }, [_vm._v(" Selesaikan formulir Profil Gizi terlebih dahulu untuk mengaktifkan formulir ini ")])], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "width": "fit-content",
      "margin-inline": "auto",
      "background-color": "secondary.50",
      "padding": "6px 12px",
      "border-radius": "12px",
      "color": "neutral.superDarkGray",
      "gap": "8px",
      "margin-bottom": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "14px"
    }
  }, [_vm._v(" Total Kebutuhan Energi ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalEnergyNeeded) + " kkal ")])], 1), _c('MealPlanSteps', {
    attrs: {
      "active-step": _vm.step,
      "draft": ((_vm$preview = _vm.preview) === null || _vm$preview === void 0 ? void 0 : _vm$preview.mealPlanSchedules) || [],
      "validation": (_vm$form$_vm$step = _vm.form[_vm.step]) !== null && _vm$form$_vm$step !== void 0 && _vm$form$_vm$step.isSkipped ? 1 : _vm.isFormInvalid || _vm.remainingTotalMealCompositionCalories === null || _vm.remainingTotalMealCompositionCalories < 0 ? -1 : 1,
      "is-loading": _vm.isLoadingSave || _vm.isLoadingData || _vm.saveState === null
    },
    on: {
      "save": _vm.onCheckSaveDraft
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "font-size": "16px",
      "margin-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "font-weight": "500",
      "color": _vm.remainingPercentTotalEnergyNeeded >= 0 ? 'primary.400' : 'danger.400'
    }
  }, [_vm._v(" Sisa kalori makanan yang dibutuhkan: " + _vm._s(_vm.remainingPercentTotalEnergyNeeded) + "% ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "6px",
      "color": "primary.400"
    }
  }, [_vm._v(" Skip jam makan "), _c('c-box', {
    attrs: {
      "cursor": !_vm.isProfilGiziValid ? 'not-allowed' : 'pointer'
    },
    on: {
      "click": function click($event) {
        return _vm.onToggleSkip(_vm.step);
      }
    }
  }, [_c('c-checkbox', {
    attrs: {
      "size": "lg",
      "variant-color": "primary",
      "pointer-events": 'none',
      "is-checked": (_vm$form$_vm$step2 = _vm.form[_vm.step]) === null || _vm$form$_vm$step2 === void 0 ? void 0 : _vm$form$_vm$step2.isSkipped,
      "is-disabled": !_vm.isProfilGiziValid
    }
  })], 1)], 1)], 1), _c('c-box', [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": "repeat(2, 1fr)",
      "gap": "16px"
    }
  }, [_c('FormTime', {
    attrs: {
      "label": "Jam Mulai Makan",
      "is-required": "",
      "is-disabled": ((_vm$form$_vm$step3 = _vm.form[_vm.step]) === null || _vm$form$_vm$step3 === void 0 ? void 0 : _vm$form$_vm$step3.isSkipped) || !_vm.isProfilGiziValid,
      "is-invalid": _vm.isInvalidField(_vm.$v.form[_vm.step].startTime),
      "invalid-text": _vm.parseErrors('Jam Mulai Makan', _vm.$v.form[_vm.step].startTime)
    },
    on: {
      "blur": _vm.$v.form[_vm.step].startTime.$touch
    },
    model: {
      value: _vm.form[_vm.step].startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.form[_vm.step], "startTime", $$v);
      },
      expression: "form[step].startTime"
    }
  }), _c('FormTime', {
    attrs: {
      "label": "Jam Akhir Makan",
      "is-required": "",
      "is-disabled": ((_vm$form$_vm$step4 = _vm.form[_vm.step]) === null || _vm$form$_vm$step4 === void 0 ? void 0 : _vm$form$_vm$step4.isSkipped) || !_vm.isProfilGiziValid,
      "is-invalid": _vm.isInvalidField(_vm.$v.form[_vm.step].endTime),
      "invalid-text": _vm.parseErrors('Jam Akhir Makan', _vm.$v.form[_vm.step].endTime)
    },
    on: {
      "blur": _vm.$v.form[_vm.step].endTime.$touch
    },
    model: {
      value: _vm.form[_vm.step].endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.form[_vm.step], "endTime", $$v);
      },
      expression: "form[step].endTime"
    }
  })], 1), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kebutuhan Jumlah Kalori",
      "placeholder": "Masukkan alokasi % kalori untuk waktu makan ini",
      "is-required": "",
      "is-disabled": ((_vm$form$_vm$step5 = _vm.form[_vm.step]) === null || _vm$form$_vm$step5 === void 0 ? void 0 : _vm$form$_vm$step5.isSkipped) || !_vm.isProfilGiziValid,
      "right-element": "%",
      "is-invalid": !_vm.isValidCaloriesNeedPercent || _vm.isInvalidField(_vm.$v.form[_vm.step].caloriesNeedPercent),
      "invalid-text": _vm.isValidCaloriesNeedPercent ? _vm.parseErrors('Jumlah Kalori', _vm.$v.form[_vm.step].caloriesNeedPercent, _vm.customMessageErrors) : "Kebutuhan jumlah kalori melebihi 100%"
    },
    on: {
      "blur": _vm.$v.form[_vm.step].caloriesNeedPercent.$touch
    },
    model: {
      value: _vm.form[_vm.step].caloriesNeedPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form[_vm.step], "caloriesNeedPercent", $$v);
      },
      expression: "form[step].caloriesNeedPercent"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kkal - Jumlah Kalori",
      "placeholder": "Jumlah Kalori",
      "is-required": "",
      "is-disabled": "",
      "right-element": "Kkal"
    },
    model: {
      value: _vm.form[_vm.step].caloriesAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form[_vm.step], "caloriesAmount", $$v);
      },
      expression: "form[step].caloriesAmount"
    }
  }), _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info', {
    attrs: {
      "variant": "info",
      "text": "Daftar golongan makanan di bawah ini opsional."
    }
  })], 1), _vm.remainingTotalMealCompositionCalories && _vm.remainingTotalMealCompositionCalories < 0 ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info', {
    attrs: {
      "variant": "warning",
      "text": "Porsi yang dimasukan melebihi kebutuhan jumlah kalori sebesar ".concat(_vm.parseFloatToFixed2(Math.abs(_vm.remainingTotalMealCompositionCalories), 2))
    }
  })], 1) : _vm._e(), _c('AccordionMealPlan', {
    attrs: {
      "menus": _vm.form[_vm.step].mealComposition,
      "is-disabled": _vm.form[_vm.step].isSkipped || !_vm.isProfilGiziValid,
      "remaining-calories": _vm.remainingTotalMealCompositionCalories
    },
    on: {
      "update:menus": _vm.onChangeMenus
    }
  })], 1)], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null || _vm.activeStepIndex <= 0
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null || _vm.activeStepIndex >= _vm.constants.steps.length - 1
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data Rencana Makan berhasil disimpan"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1
                }
              }));
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Ups, Gagal Menyimpan Data",
      "description": "Mohon maaf, sepertinya terdapat kesalahan data atau data belum terisi semua. Mohon untuk mengecek ulang data anda."
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }