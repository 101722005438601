<template>
  <c-box>
    <BaseAccordionList
      :allow-toggle="false"
    >
      <BaseAccordionListItem
        :label="item?.foodGroup"
        :is-disabled="isDisabled || !item?.isActive"
        :is-active="item?.isActive ? true : false"
        :is-error="isErrorAccordionListItem ? true : false"
        :is-open="item?.isActive ? true : false"
      >
        <template #header>
          <c-flex
            height="100%"
            align-items="center"
            gap="8px"
            color="#FFF"
            font-size="14px"
            margin-right="16px"
            :cursor="isDisabled ? 'not-allowed' : 'pointer'"
            @click.stop="onToggleActive"
          >
            <c-text
              v-if="!item?.isActive"
            >
              Tidak Aktif
            </c-text>
            <c-flex
              v-chakra="{
                ':hover': {
                  backgroundColor: isDisabled ? 'neutral.white' : 'primary.50',
                }
              }"
              position="relative"
              width="60px"
              height="25px"
              align-items="center"
              border-radius="25px"
              background-color="#FFF"
            >
              <c-box
                position="absolute"
                width="20px"
                height="20px"
                border-radius="20px"
                :left="item?.isActive ? '35px' : '5px'"
                :background-color="item?.isActive ? 'primary.400' : 'neutral.lightGray'"
              />
            </c-flex>
            <c-text
              v-if="item?.isActive"
            >
              Aktif
            </c-text>
          </c-flex>
        </template>
        <template #default>
          <c-flex
            width="100%"
            padding-block="8px"
            border-bottom="1px solid"
            border-bottom-color="neutral.lightGray"
          >
            <c-box
              width="200px"
              flex-shrink="0"
            >
              <c-text
                font-size="16px"
                font-weight="500"
                margin-bottom="12px"
              >
                Jumlah Porsi
              </c-text>
              <c-flex
                flex-direction="column"
                width="150px"
                gap="8px"
              >
                <c-form-control
                  :is-invalid="((remainingCalories && remainingCalories < 0) || !item?.calories)"
                  margin-bottom="16px"
                >
                  <c-input-group>
                    <c-input-left-element>
                      <c-flex
                        as="button"
                        justify-content="center"
                        align-items="center"
                        width="40px"
                        height="100%"
                        border-width="1px"
                        border-style="solid"
                        :border-color="((remainingCalories && remainingCalories < 0) || !item?.calories) ? 'danger.400' : 'primary.400'"
                        border-top-left-radius="6px"
                        border-bottom-left-radius="6px"
                        cursor="pointer"
                        @click="onAdjustPortion(-0.25)"
                      >
                        <c-box
                          width="15px"
                          height="auto"
                        >
                          <inline-svg
                            :src="require('@/assets/icons/icon-minus.svg')"
                            height="100%"
                            width="100%"
                            :fill="((remainingCalories && remainingCalories < 0) || !item?.calories) ? '#D32737' : '#008C81'"
                          />
                        </c-box>
                      </c-flex>
                    </c-input-left-element>
                    <c-input
                      :value="_portion"
                      type="text"
                      width="150px"
                      text-align="center"
                      padding-inline="40px"
                      font-size="20px"
                      border-radius="6px"
                      :border-color="remainingCalories && remainingCalories < 0 ? 'danger.400' : '#C4C4C4'"
                      :_focus="{
                        outline: 'none',
                      }"
                      @keydown="onKeyDownPortion"
                      @input="debouncePortion"
                    />
                    <c-input-right-element>
                      <c-flex
                        as="button"
                        justify-content="center"
                        align-items="center"
                        width="40px"
                        height="100%"
                        border-width="1px"
                        border-style="solid"
                        :border-color="((remainingCalories && remainingCalories < 0) || !item?.calories) ? 'danger.400' : 'primary.400'"
                        border-top-right-radius="6px"
                        border-bottom-right-radius="6px"
                        cursor="pointer"
                        @click="onAdjustPortion(0.25)"
                      >
                        <c-box
                          width="15px"
                          height="auto"
                        >
                          <inline-svg
                            :src="require('@/assets/icons/icon-plus.svg')"
                            height="100%"
                            width="100%"
                            :fill="((remainingCalories && remainingCalories < 0) || !item?.calories) ? '#D32737' : '#008C81'"
                          />
                        </c-box>
                      </c-flex>
                    </c-input-right-element>
                  </c-input-group>
                  <c-form-helper-text
                    v-if="((remainingCalories && remainingCalories < 0) || !item?.calories)"
                    :font-size="['12px', '16px']"
                    :font-weight="['400', '400']"
                    d="flex"
                    align-items="center"
                    gap="10px"
                    color="#D32737"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-circle-warn.svg')"
                      height="20px"
                      width="20px"
                      fill="#D32737"
                    />
                    <c-text>
                      Cek Ulang Data
                    </c-text>
                  </c-form-helper-text>
                </c-form-control>
                <c-flex
                  width="100%"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  padding="8px"
                  border-radius="12px"
                  :background-color="((remainingCalories && remainingCalories < 0) || !item?.calories) ? 'danger.50' : 'primary.50'"
                >
                  <c-text
                    font-size="14px"
                  >
                    Jumlah Kalori
                  </c-text>
                  <c-text
                    font-size="20px"
                    font-weight="500"
                  >
                    {{ item?.calories ?? '--' }} Kkal
                  </c-text>
                </c-flex>
              </c-flex>
            </c-box>
            <c-box
              flex-grow="1"
            >
              <c-text
                color="neutral.darkGray"
                font-size="16px"
                font-weight="500"
                margin-bottom="12px"
              >
                Pilihan bahan makanan
              </c-text>
              <c-grid
                width="100%"
                template-columns="repeat(4, 1fr)"
                gap="16px"
              >
                <c-flex
                  v-for="(food, i) in item?.defaultItems"
                  :key="`default-${i}`"
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                  :opacity="isIncludeDefaultItem(food?.id) ? '1' : '0.5'"
                >
                  <c-box
                    position="relative"
                    cursor="pointer"
                    @click="onToggleDefaultItem(food)"
                  >
                    <c-box
                      position="absolute"
                      top="4px"
                      right="6px"
                    >
                      <c-checkbox
                        size="lg"
                        variant-color="primary"
                        :pointer-events="'none'"
                        :is-checked="isIncludeDefaultItem(food?.id)"
                      />
                    </c-box>
                    <c-image
                      :src="food?.photoUrl"
                      width="72px"
                      height="72px"
                      object-fit="cover"
                      object-position="center"
                      border-radius="8px"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      margin-bottom="8px"
                    />
                  </c-box>
                  <c-text
                    font-size="14px"
                    text-align="center"
                  >
                    {{ food?.foodName }}
                  </c-text>
                  <c-text
                    font-size="14px"
                    color="#888"
                  >
                    {{ onAdjustPortionMealItems(food?.householdMeasurementPerPortion) }} {{ food?.householdMeasurementUnitPerPortion }}
                  </c-text>
                </c-flex>
                
                <c-flex
                  v-for="(exclude, j) in listFoodsExcludeDefaultItems"
                  :key="`exclude-${j}`"
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                >
                  <c-box
                    position="relative"
                    cursor="pointer"
                    @click="onRemoveExcludeDefaultItems(exclude)"
                  >
                    <c-box
                      position="absolute"
                      top="2px"
                      right="2px"
                    >
                      <c-box
                        width="20px"
                        height="auto"
                      >
                        <inline-svg
                          :src="require('@/assets/icon-close-black.svg')"
                          height="100%"
                          width="100%"
                          fill="#555"
                        />
                      </c-box>
                    </c-box>
                    <c-image
                      :src="exclude?.photoUrl"
                      width="72px"
                      height="72px"
                      object-fit="cover"
                      object-position="center"
                      border-radius="8px"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      margin-bottom="8px"
                    />
                  </c-box>
                  <c-text
                    font-size="14px"
                    text-align="center"
                  >
                    {{ exclude?.foodName }}
                  </c-text>
                  <c-text
                    font-size="14px"
                    color="#888"
                  >
                    {{ onAdjustPortionMealItems(exclude?.householdMeasurementPerPortion) }} {{ exclude?.householdMeasurementUnitPerPortion }}
                  </c-text>
                </c-flex>

                <c-flex
                  v-for="(additional, k) in item?.additionalMealItems"
                  :key="`additional-${k}`"
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                >
                  <c-box
                    position="relative"
                    cursor="pointer"
                    @click="onRemoveAdditionalMealItems(additional)"
                  >
                    <c-box
                      position="absolute"
                      top="2px"
                      right="2px"
                    >
                      <c-box
                        width="20px"
                        height="auto"
                      >
                        <inline-svg
                          :src="require('@/assets/icon-close-black.svg')"
                          height="100%"
                          width="100%"
                          fill="#555"
                        />
                      </c-box>
                    </c-box>
                    <c-image
                      :src="additional?.photoUrl ? additional?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                      width="72px"
                      height="72px"
                      object-fit="cover"
                      object-position="center"
                      border-radius="8px"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      margin-bottom="8px"
                    />
                    <!-- <c-box
                      v-else
                      width="72px"
                      height="72px"
                      object-fit="cover"
                      object-position="center"
                      border-radius="8px"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      margin-bottom="8px"
                    >
                      <inline-svg
                        :src="require('@/assets/icons/icon-image-preview.svg')"
                        height="100%"
                        width="100%"
                        fill="#008C81"
                      />
                    </c-box> -->
                  </c-box>
                  <c-text
                    font-size="14px"
                    text-align="center"
                  >
                    {{ additional?.foodName }}
                  </c-text>
                  <c-text
                    font-size="14px"
                    color="#888"
                  >
                    {{ onAdjustPortionMealItems(additional?.householdMeasurement) }} {{ additional?.householdMeasurementUnit }}
                  </c-text>
                </c-flex>

                <c-flex
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                >
                  <c-button
                    variant="outline"
                    variant-color="primary"
                    justify-content="center"
                    align-items="center"
                    width="72px"
                    height="72px"
                    border-radius="8px"
                    margin-bottom="8px"
                    @click="onOpenModalFood"
                  >
                    <c-box
                      width="40px"
                      height="auto"
                    >
                      <inline-svg
                        :src="require('@/assets/icons/icon-plus.svg')"
                        height="100%"
                        width="100%"
                        fill="#008C81"
                      />
                    </c-box>
                  </c-button>
                  <c-text
                    font-size="14px"
                    text-align="center"
                    color="#888"
                  >
                    Tambah makanan lainnya
                  </c-text>
                </c-flex>
              </c-grid>
            </c-box>
          </c-flex>
        </template>
      </BaseAccordionListItem>
    </BaseAccordionList>
    <BaseModal
      :is-open="isOpenModalFood"
      :close-on-overlay-click="false"
      :with-button-close="false"
      size="700px"
    >
      <template #header>
        <c-box
          position="relative"
        >
          <c-flex
            width="100%"
            padding="16px"
            justify-content="center"
            align-items="center"
          >
            Detail Menu {{ item?.foodGroup }}
          </c-flex>
          <c-button
            variant="ghost"
            position="absolute"
            right="12px"
            top="8px"
            width="40px"
            height="40px"
            @click="onCloseModalFood"
          >
            <c-icon
              name="close"
              size="20px"
            />
          </c-button>
        </c-box>
      </template>
      <template #body>
        <c-flex
          position="relative"
          flex-direction="column"
          justify-content="center"
          text-align="center"
        >
          <c-box
            margin-inline="24px"
            padding-bottom="8px"
          >
            <c-form-control
              width="100%"
              pos="relative"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left="15px"
                height="24px"
                z-index="2"
              />
              <c-input
                id="search"
                v-model="filter.search"
                type="text"
                w="100%"
                height="48px"
                placeholder="Pencarian"
                border-radius="20px"
                padding-left="50px"
                position="relative"
                :font-size="['14px', '16px']"
                z-index="1"
                @keyup="onChangeSearch"
              />
            </c-form-control>
          </c-box>
          <c-box
            v-if="!isLoadingDataFood"
            max-height="400px"
            height="100%"
            overflow-y="auto"
            padding-block="8px"
            padding-inline="24px"
          >
            <c-box
              v-if="listFoods.length > 0"
            >
              <c-grid
                width="100%"
                template-columns="repeat(5, 1fr)"
                gap="8px"
              >
                <c-flex
                  v-for="(food, i) in listFoods"
                  :key="i"
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                >
                  <c-box
                    position="relative"
                    cursor="pointer"
                    @click="onToggleSelectedFoods(food)"
                  >
                    <c-box
                      position="absolute"
                      top="4px"
                      right="6px"
                    >
                      <c-checkbox
                        size="lg"
                        variant-color="primary"
                        :pointer-events="'none'"
                        :is-checked="isIncludeSelectedFoods(food?.id)"
                      />
                    </c-box>
                    <c-image
                      :src="food?.photoUrl"
                      width="72px"
                      height="72px"
                      object-fit="cover"
                      object-position="center"
                      border-radius="8px"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      margin-bottom="8px"
                    />
                  </c-box>
                  <c-text
                    font-size="14px"
                  >
                    {{ food?.foodName }}
                  </c-text>
                  <c-text
                    font-size="14px"
                    color="#888"
                  >
                    {{ onAdjustPortionMealItems(food?.householdMeasurementPerPortion) }} {{ food?.householdMeasurementUnitPerPortion }}
                  </c-text>
                </c-flex>
                <c-flex
                  v-if="isShowFormOtherFood"
                  position="relative"
                  flex-direction="column"
                  justify-content="flex-start"
                  align-items="center"
                  width="100%"
                >
                  <c-box
                    position="absolute"
                    top="2px"
                    right="2px"
                    cursor="pointer"
                    @click="onRemoveOtherFood"
                  >
                    <c-box
                      width="20px"
                      height="auto"
                    >
                      <inline-svg
                        :src="require('@/assets/icon-close-black.svg')"
                        height="100%"
                        width="100%"
                        fill="#555"
                      />
                    </c-box>
                  </c-box>
                  <c-box
                    height="72px"
                    width="72px"
                    margin-bottom="4px"
                  >
                    <!-- hide form input image -->
                    <c-box
                      v-if="false || formAdditional.imageDefault?.blobPreview || !isDataEmpty(formAdditional.imageDefault)"
                      position="relative"
                      d="inline-block"
                      cursor="pointer"
                      @click="handleChangePhoto('delete', $event)"
                    >
                      <c-image
                        :src="formAdditional.imageDefault?.blobPreview || formAdditional.imageDefault"
                        height="72px"
                        width="72px"
                        rounded="8px"
                        object-fit="cover"
                        object-position="center"
                        box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                      />
                    </c-box>
                    <c-box
                      v-else
                      p="0"
                      h="100%"
                      w="100%"
                      as="label"
                      d="inline-block"
                      rounded="8px"
                      overflow="hidden"
                      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                    >
                      <inline-svg
                        :src="require('@/assets/icons/icon-image-preview.svg')"
                        height="100%"
                        width="100%"
                        fill="#008C81"
                      />
                      <!-- <c-input
                        type="file"
                        hidden
                        @change="handleChangePhoto('add', $event)"
                      /> -->
                    </c-box>
                  </c-box>
                  <c-input
                    v-model="formAdditional.foodName"
                    type="text"
                    w="100%"
                    height="24px"
                    placeholder="Nama makanan"
                    position="relative"
                    font-size="12px"
                    text-align="center"
                    padding-inline="4px"
                    margin-bottom="4px"
                  />
                  <c-grid
                    width="100%"
                    template-columns="repeat(2, 1fr)"
                    gap="4px"
                  >
                    <c-input
                      v-model="formAdditional.householdMeasurement"
                      type="text"
                      w="100%"
                      height="24px"
                      placeholder="Ukuran"
                      position="relative"
                      font-size="12px"
                      text-align="center"
                      padding-inline="4px"
                    />
                  
                    <c-input
                      v-model="formAdditional.householdMeasurementUnit"
                      type="text"
                      w="100%"
                      height="24px"
                      placeholder="Takaran"
                      position="relative"
                      font-size="12px"
                      text-align="center"
                      padding-inline="4px"
                    />
                  </c-grid>
                  <c-box
                    text-align="center"
                    font-size="12px"
                    color="neutral.888888"
                    style="text-wrap: pretty;"
                  >
                    Masukkan ukuran untuk 1 porsi
                  </c-box>
                </c-flex>
              </c-grid>
              <c-flex
                justify-content="center"
                w="100%"
                margin-top="16px"
              >
                <c-flex justify-content="center">
                  <c-button
                    variant="ghost"
                    variant-color="primary"
                    px="0"
                    py="4px"
                    w="32px"
                    h="32px"
                    :is-disabled="filter.page <= 1"
                    @click.prevent="onPreviousMenus"
                  >
                    <c-icon
                      name="chevron-left"
                      size="24px"
                    />
                  </c-button>

                  <c-button
                    v-for="page_ in pages"
                    :key="page_"
                    :variant="page_ === filter.page ? 'outline' : 'ghost'"
                    variant-color="primary"
                    px="0"
                    py="4px"
                    w="32px"
                    h="32px"
                    mx="4px"
                    @click="onChangePageMenus(page_)"
                  >
                    {{ page_ }}
                  </c-button>

                  <c-button
                    variant="ghost"
                    variant-color="primary"
                    px="0"
                    py="4px"
                    w="32px"
                    h="32px"
                    :is-disabled="pages.length === filter.page"
                    @click.prevent="onNextMenus"
                  >
                    <c-icon
                      name="chevron-right"
                      size="24px"
                    />
                  </c-button>
                </c-flex>
              </c-flex>
            </c-box>
            <c-flex
              v-else
              width="100%"
              min-height="100px"
              justify-content="center"
              align-items="center"
            >
              <c-text
                font-size="18px"
                font-weight="500"
                color="danger.400"
              >
                Data tidak ditemukan
              </c-text>
            </c-flex>
          </c-box>
          <c-box
            v-else
            text-align="center"
            margin-block="36px"
          >
            <c-spinner
              thickness="4px"
              speed="0.65s"
              empty-color="green.200"
              color="primary.400"
              size="xl"
            />
            <c-text
              color="primary.400"
              font-weight="500"
            >
              Memuat data...
            </c-text>
          </c-box>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="8px"
          width="100%"
          align-items="flex-end"
          padding-top="8px"
          padding-left="16px"
          padding-right="16px"
          margin-bottom="30px"
        >
          <c-flex
            position="relative"
            flex-direction="column"
            justify-content="flex-start"
            align-items="center"
            width="110px"
          >
            <c-button
              variant="outline"
              variant-color="primary"
              justify-content="center"
              align-items="center"
              width="65px"
              height="65px"
              border-radius="8px"
              margin-bottom="8px"
              :is-disabled="isShowFormOtherFood"
              @click="onAddOtherFood"
            >
              <c-box
                width="35px"
                height="auto"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-plus.svg')"
                  height="100%"
                  width="100%"
                  fill="#008C81"
                />
              </c-box>
            </c-button>
            <c-text
              position="absolute"
              bottom="-26px"
              width="130px"
              font-size="14px"
              text-align="center"
              color="#888"
              line-height="1.25"
            >
              Tambah makanan lainnya
            </c-text>
          </c-flex>
          <BaseButton
            flex-grow="1"
            rounded="1000px"
            width="100%"
            :is-disabled="isDisabledConfirmModalFood"
            :is-loading="isLoadingModalFood"
            @click="onConfirmModalFood"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import BaseAccordionList from '@/components/elements/base-accordion-list'
import BaseAccordionListItem from '@/components/elements/base-accordion-list-item'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { isDataEmpty } from '@/utils/is-data-empty'
// import imageCompression from 'browser-image-compression'
import Fraction from 'fraction.js'
import { convertToFraction } from '@/utils/fraction'

export default {
  components: {
    BaseAccordionList,
    BaseAccordionListItem,
    BaseModal,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    remainingCalories: {
      type: [Number, null],
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:item'],
  data() {
    return {
      isOpenModalFood: false,
      isLoadingDataFood: false,
      isLoadingModalFood: false,
      fraction: null,
      filter: {
        search: '',
        page: 1,
        perPage: 9,
        groupFood: '',
        total: 0,
      },
      debounceSearch: _.debounce(this.onSearch, 1000),
      debouncePortion: _.debounce(this.onChangePortion, 1000),
      listFoods: [],
      selectedFoods: [],
      selectedFoodsDuplicate: null,
      formAdditional: {
        foodName: '',
        imageDefault: null,
        householdMeasurement: null,
        householdMeasurementUnit: null,
      },
      isShowFormOtherFood: false,
    }
  },
  computed: {
    _portion: {
      get() {
        return this.item?.portion ?? ''
      },
      set(val) {
        const portionDecimal = this.convertToDecimal(val)

        let calories = portionDecimal ? (parseFloat(portionDecimal) * parseFloat(this.item?.calory)) : null

        if (calories && !Number.isInteger(calories)) {
          calories = calories.toFixed(2)
        }

        const item = {
          ...this.item,

          calories,
          portion: val,
          portionDecimal,
        }
        this.$emit('update:item', item)
      },
    },
    _portionDecimal() {
      return this.item?.portionDecimal || 0
    },
    isErrorAccordionListItem() {
      return ((this.remainingCalories && this.remainingCalories < 0) || !this.item?.calories)
    },
    isDisabledConfirmModalFood() {
      if (this.isShowFormOtherFood) {
        return (
          !this.formAdditional.foodName ||
          // !this.formAdditional.imageDefault ||
          !this.formAdditional.householdMeasurement ||
          !this.formAdditional.householdMeasurementUnit
        )
      }
      return this.hasSameSelectedFoods
    },
    listFoodsExcludeDefaultItems() {
      if (this.item?.mealItems?.length > 0) {
        return this.item?.mealItems.filter((it) => !this.item?.defaultItems?.some((t) => t?.id === it?.id))
      }
      return []
    },
    hasSameSelectedFoods() {
      return _.isEqual(this.selectedFoods, this.selectedFoodsDuplicate)
    },
    totalPage() {
      return Math.ceil(this.filter.total / Number(this.filter.perPage))
    },
    pages() {
      let total = this.filter.total ?? 1
      let perPage = this.filter.perPage ?? 14
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
  },
  methods: {
    isDataEmpty,
    convertToFraction,
    onToggleActive() {
      if (!this.isDisabled) {
        const item = {
          ...this.item,
          isActive: !this.item?.isActive,
        }
        this.$emit('update:item', item)
      }
    },
    onKeyDownPortion(event) {
      const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '/', ' ']
      
      const inputValue = this._portion
      const hasDecimal = inputValue.includes('.')
      const hasSlash = inputValue.includes('/')

      if (
        allowedKeys.includes(event.key) ||
        (event.key === '.' && !hasDecimal && !hasSlash) ||
        (event.key === '/' && !hasSlash)
      ) {
        return
      } else {
        event.preventDefault()
      }
    },
    gcd(a, b) {
      return b ? this.gcd(b, a % b) : a
    },
    normalizeFraction(value) {
      if (value) {
        try {
          const x = new Fraction(value)
          return x.toFraction(true)
        } catch(err) {
          this.$toast({
            title: 'Error',
            description: err?.message,
            status: 'error',
            variant: 'subtle',
            position: 'bottom-right',
          })
        }
      }
      return value
    },
    covertToFraction(fraction) {
      let value = fraction
      const decimalRegex = /^\d*\.?\d{0,2}$/
      // const fractionRegex = /[1-9][0-9]*\/[1-9][0-9]*/g

      if (value.toString().endsWith('.')) {
        value = value + '0'
      }

      if (decimalRegex.test(value)) {
        let decimal = parseFloat(value)
        if (!isNaN(decimal)) {
          let [integerPart, decimalPart] = decimal.toString().split('.')
          if (!decimalPart) {
            return integerPart
          } else {
            let numerator = parseInt(decimalPart)
            let denominator = Math.pow(10, decimalPart.length)
            let gcd = this.gcd(numerator, denominator)
            numerator = numerator / gcd
            denominator = denominator / gcd
            if (integerPart !== '0') {
              numerator = parseInt(integerPart) * denominator + numerator
            }
            return `${numerator}/${denominator}`
          }
        }
      }
      return value
    },
    onChangePortion(value) {
      const _portion = this.covertToFraction(value)
      const _portion2 = this.normalizeFraction(value)
      this._portion = _portion2
    },
    convertToDecimal(value) {
      let fraction = value
      const decimalRegex = /^\d*\.?\d{0,2}$/
      const fractionRegex = /[1-9][0-9]*\/[1-9][0-9]*/g
      
      if (fraction.toString().endsWith('/')) {
        fraction = fraction + '1'
      }

      if (decimalRegex.test(fraction)) {
        return fraction
      } else if (fractionRegex.test(fraction)) {
        try {
          const x = new Fraction(fraction)
          return parseFloat(x.n / x.d)
        } catch (error) {
          this.$toast({
            title: 'Error',
            description: `Error converting fraction to decimal: ${error}`,
            status: 'error',
            variant: 'subtle',
            position: 'bottom-right',
          })
          return fraction
        }
      } else {
        return value
      }
    },
    onAdjustPortion(amount) {
      let portionDecimal = (parseFloat(this._portionDecimal) + amount)
      
      if (portionDecimal < 0) {
        portionDecimal = 0
      }

      const portion = this.covertToFraction(portionDecimal)
      const portion2 = this.normalizeFraction(portion)
      let calories = portionDecimal ? (parseFloat(portionDecimal) * parseFloat(this.item?.calory)) : null

      if (calories && !Number.isInteger(calories)) {
        calories = calories.toFixed(2)
      }

      const item = {
        ...this.item,
        calories,
        portion: portion2,
        portionDecimal,
      }
      this.$emit('update:item', item)
    },
    onAdjustPortionMealItems(size) {
      const parseSize = size ? parseFloat(size) : 0
      if (this._portionDecimal) {
        const result = parseFloat(this._portionDecimal) * parseSize

        if (Number.isInteger(result)) {
          return result
        }

        const formattedNumber = result
        return this.convertToFraction(formattedNumber)
      }
      return parseSize
    },
    isIncludeDefaultItem(foodId) {
      if (this.item?.mealItems.length > 0) {
        return this.item.mealItems.some((it) => it?.id === foodId)
      }
      return false
    },
    onToggleDefaultItem(food) {
      const isSelected = this.isIncludeDefaultItem(food?.id)
      let mealItems = this.item?.mealItems
      if (isSelected) {
        mealItems = this.item.mealItems.filter((it) => it?.id !== food?.id)
      } else {
        mealItems.push(food)
      }
      const item = {
        ...this.item,
        mealItems,
      }
      this.$emit('update:item', item)
    },
    onChangeSearch() {
      this.debounceSearch()
    },
    async onSearch() {
      this.isLoadingDataFood = true
      const res = await reqNutriMealPlan.getMealGlossaries(this.$store.getters.axios, {
        filter: this.item?.foodGroupMealGlossaries,
        q: this.filter.search,
        page: this.filter.page,
        perpage: this.filter.perPage,
      })
      this.listFoods = res.data.data?.result
      this.filter.total = res.data.meta?.total || 0
      this.isLoadingDataFood = false
    },
    onAddOtherFood() {
      this.isShowFormOtherFood = true
    },
    onRemoveOtherFood() {
      this.isShowFormOtherFood = false
      this.onResetOtherFood()
    },
    onResetOtherFood() {
      this.formAdditional.foodName = ''
      // this.formAdditional.imageDefault = null
      this.formAdditional.householdMeasurement = null
      this.formAdditional.householdMeasurementUnit = null
    },
    onOpenModalFood() {
      this.isOpenModalFood = true
      this.selectedFoods = this.item?.mealItems || []
      this.selectedFoodsDuplicate = _.cloneDeep(this.selectedFoods)
      this.onSearch()
    },
    onCloseModalFood() {
      this.isOpenModalFood = false
      this.isShowFormOtherFood = false
      this.onResetOtherFood()
    },
    async onConfirmModalFood() {
      if (!this.isDisabledConfirmModalFood && !this.isLoadingModalFood) {
        try {
          this.isLoadingModalFood = true
          let item = {
            ...this.item,
            mealItems: this.selectedFoods,
          }
          if (this.isShowFormOtherFood) {
            // let options = {
            //   maxSizeMB: 0.5,
            //   maxWidthOrHeight: 1920,
            //   useWebWorker: true,
            // }
            // const compressedFile = imageCompression(this.formAdditional.imageDefault?.file, options)
            // let formData = new FormData()
            // let fileName = Date.now()
            // let file = new File([compressedFile], fileName)
            // formData.append('file', file, fileName)
            
            // let formData = new FormData()
            // formData.append('file', this.formAdditional.imageDefault?.file)
  
            // const res = await reqNutriMealPlan.postUpload(this.$store.getters.axios, formData)

            let additionalHouseholdMeasurement = this.convertToDecimal(this.formAdditional.householdMeasurement)
  
            let additionalMealItems = [
              ...this.item?.additionalMealItems,
              {
                id: Date.now(),
                foodName: this.formAdditional.foodName,
                // imageDefault: res.data.data?.url,
                householdMeasurement: additionalHouseholdMeasurement,
                householdMeasurementUnit: this.formAdditional.householdMeasurementUnit,
              },
            ]
  
            item = {
              ...item,
              additionalMealItems,
            }
          }
          this.$emit('update:item', item)
          this.onCloseModalFood()
        } catch (err) {
          this.$toast({
            title: 'Failed',
            description: err?.response?.data?.message || 'Something went wrong!',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
          })
        } finally {
          this.isLoadingModalFood = false
        }
      }
    },
    isIncludeSelectedFoods(foodId) {
      if (this.selectedFoods.length > 0) {
        return this.selectedFoods.some((it) => it?.id === foodId)
      }
      return false
    },
    onToggleSelectedFoods(food) {
      const isSelected = this.isIncludeSelectedFoods(food?.id)
      if (isSelected) {
        this.selectedFoods = this.selectedFoods.filter((it) => it?.id !== food?.id)
      } else {
        this.selectedFoods = [
          ...this.selectedFoods,
          food,
        ]
      }
    },
    onRemoveExcludeDefaultItems(food) {
      let mealItems = this.item.mealItems.filter((it) => it?.id !== food?.id)
      const item = {
        ...this.item,
        mealItems: mealItems,
      }
      this.$emit('update:item', item)
    },
    onRemoveAdditionalMealItems(additional) {
      let additionalMealItems = this.item.additionalMealItems.filter((it) => it?.id !== additional?.id)
      const item = {
        ...this.item,
        additionalMealItems: additionalMealItems,
      }
      this.$emit('update:item', item)
    },
    onPreviousMenus() {
      if (this.filter.page > 1) {
        this.onChangePageMenus(this.filter.page - 1)
      }
    },
    onNextMenus() {
      if (this.filter.page < this.totalPage) {
        this.onChangePageMenus(this.filter.page + 1)
      }
    },
    onChangePageMenus(value) {
      if (this.filter.page !== value) {
        this.filter.page = value
        this.onSearch()
      }
    },
    handleChangePhoto(type, e) {
      if (type === 'add') {
        const imageFile = e.target.files?.[0]
        this.formAdditional.imageDefault = {
          file: imageFile,
          blobPreview: URL.createObjectURL(imageFile),
        }
        return
      }

      if (type === 'delete') {
        this.formAdditional.imageDefault = null
        return
      }
    },
  },
}
</script>

<style>

</style>